import { DeepOmit } from '@/types/helpers/DeepOmit.ts';
import { ReplaceSuffix } from '@/types/helpers/DeepReplace.ts';
import { ruTranslations } from '@/utils/configs/locales/ru.ts';

export const kzTranslations: ReplaceSuffix<
  DeepOmit<typeof ruTranslations, `${string}_few`>,
  '_many',
  '_other'
> = {
  translation: {
    api: {
      ACCESS_DENIED: 'Пайдаланушы авторизациядан өткен, бірақ қол жеткізу тыйым салынған!',
      ALREADY_ACTIVATED: 'Код немесе пайдаланушы белсендірілген!',
      AUTH_ERROR: 'Қате нөмір немесе код',
      AUTH_ERROR_BLOCKED: 'Есептік жазба бұғатталған. Қолдау қызметіне хабарласыңыз',
      AUTH_ERROR_DISABLED: 'Есептік жазба өшірілген. Қолдау қызметіне хабарласыңыз',
      AUTH_ERROR_MODERATION: 'Есептік жазба модерацияда',
      BAD_REQUEST: 'Дұрыс емес сұраныс',
      EXPIRED_TOKEN:
        'Сессияңыз аяқталды немесе осы ресурсқа қол жеткізуге тыйым салынған. Өтінеміз, қайтадан кіріңіз және тағы бір рет көріңіз.',
      INTERNAL_ERROR: 'Сервер қатесі, администратормен хабарласыңыз!',
      INVALID_EMAIL_FORMAT: 'Электрондық поштаның қате форматы',
      INVALID_OTP_EXPIRED: 'Қате код (мерзімі аяқталған)',
      INVALID_OTP_NOT_FOUND: 'Қате код (код тіркелмеген)',
      INVALID_OTP_USED: 'Қате код (қолданылған)',
      INVALID_RESET_CODE: 'Қате қалпына келтіру коды',
      INVALID_TOKEN: 'Қате токен!',
      SYSTEM_ERROR: 'Сервер қатесі, администратормен хабарласыңыз!',
      TRIAL_TARIFF_IN_USE: 'Сынақ мерзімі бұрын қолданылған',
      UNIQUE_RESOURCE_CONFLICT: 'Уникалдық ресурспен қақтығыс бар',
      USER_ALREADY_EXISTS: 'Бұл нөмір бұрын тіркелген. Жүйеге кіріңіз',
      USER_NOT_FOUND: 'Бұл нөмір табылмады, тіркеліңіз',
      WRONG_ACCOUNT_STATUS: 'Есептік жазбаның жарамсыз күйі',
      apiRequestFailed: 'API сұрауы орындалмады, кейінірек тағы бір рет көріңіз!',
      apiTimeoutMessage:
        'API сұрауының күту уақыты аяқталды, бетті жаңартыңыз және тағы бір рет көріңіз!',
      badCredentials: 'Қате нөмір немесе код',
      errMsg401:
        'Сессияңыз аяқталды немесе осы ресурсқа қол жеткізуге тыйым салынған. Өтінеміз, қайтадан кіріңіз және тағы бір рет көріңіз.',
      errMsg403: 'Пайдаланушы авторизацияланған, бірақ қол жеткізу тыйым салынған!',
      errMsg404: 'Желілік сұраныс қатесі, ресурс табылмады!',
      errMsg405: 'Желілік сұраныс қатесі, сұрау әдісіне рұқсат жоқ!',
      errMsg408: 'Желілік сұраныстың күту уақыты аяқталды!',
      errMsg500: 'Сервер қатесі, администратормен хабарласыңыз!',
      errMsg501: 'Желі іске қосылмаған!',
      errMsg502: 'Желілік қате!',
      errMsg503: 'Қызмет қол жетімді емес, сервер уақытша жүктемеде немесе қызмет көрсетуде!',
      errMsg504: 'Желі күту уақыты аяқталды!',
      errMsg505: 'HTTP нұсқасы сұрауды қолдамайды!',
      errorMessage: 'Операция сәтсіз аяқталды, жүйеде ақаулық бар!',
      errorTip: 'Қате туралы анықтама',
      networkException: 'Желілік аномалия',
      networkExceptionMsg:
        'Өтінеміз, желілік қосылымыңыздың қалыпты екендігін тексеріңіз! Желі аномалдық жағдайда',
      noTokenInResponse: 'Жауапта токен жоқ',
      operationFailed: 'Операция сәтсіз аяқталды',
      operationSuccess: 'Операция сәтті аяқталды',
      successTip: 'Сәтті орындалғаны туралы анықтама ',
      timeoutMessage: 'Кіру уақыты аяқталды, қайта кіріңіз!',
    },
    auth: {
      accountExists: 'Аккаунтыңыз бар ма?',
      accountNotExists: 'Аккаунтыңыз жоқ па?',
      authorization: 'Авторизация',
      codeConfirmation: 'Кодты растау',
      codeGet: 'Код алу',
      codeResendAfter: 'Кодты қайта сұратуға болады:',
      codeResentTimer: 'Кодты қайта сұратуға болады: {{seconds}} секунд',
      codeSeconds: 'секунд',
      confirmLogout: 'Шығуға сенімдісіз бе?',
      logout: 'Шығу',
      nameInput: 'Атыңызды енгізіңіз',
      phoneInput: 'Телефон нөмірін енгізіңіз',
      sendAgain: 'Қайта жіберу',
      signin: 'Кіру',
      signinAction: 'Кіру',
      signup: 'Тіркелу',
      signupAction: 'Тіркелу',
      signupToContinue: 'Пожалуйста, зарегистрируйтесь для продолжения',
    },
    categories: {
      choose: 'Санатты таңдаңыз',
      productCategory: 'Тауарлар санаты',
      productCategory_other: 'Тауарлар санаттары',
    },
    common: {
      LTD: 'ЖШС',
      accepted: 'Жіберілді',
      accountNumber: 'Тұлғалық шот',
      active: 'Белсенді',
      active_other: 'Белсенді',
      actives: 'Белсенділер',
      addPhoto: 'Суретті қосу',
      available: 'Қолжетімді',
      back: 'Артқа',
      balance: 'Баланс',
      call: 'Қоңырау шалу',
      camera: 'Камера',
      cancel: 'Болдырмау',
      cancel_action: 'Жою',
      category: 'Санат',
      choose: 'Таңдаңыз',
      chooseMethod: 'Таңдау әдісі',
      chosen: 'Таңдалды',
      close: 'Жабу',
      closed: 'Жабық',
      comment: 'Пікір',
      contact: 'Байланысу',
      contactSeller: 'Сатушымен байланысу',
      contactWhatsApp: 'WhatsApp арқылы хабарласыңыз',
      continue: 'Жалғастыру',
      dateRequest: 'Сұрау күні',
      dateSignup: 'Тіркелу күні',
      declined: 'Қабылданбады',
      editData: 'Деректерді өзгерту',
      error: 'Қате',
      find: 'Табу',
      fullNameShort: 'Т.А.Ә.',
      history: 'История',
      inputData: 'Деректерді енгізу',
      loading: 'Жүктелуде...',
      market: 'Базар',
      missed: 'Жоғалған',
      missed_other: 'Жоғалғандар',
      missed_s: 'Жоғалғандар',
      moreDetails: 'Толығырақ',
      next: 'Далее',
      no: 'Жоқ',
      noAccess: 'Қол жеткізу жоқ',
      notActive: 'Белсенді емес',
      offer: 'Ұсыныс',
      ok: 'Жарайды',
      onMap: 'Картада',
      phoneNumber: 'Телефон нөмірі',
      photoLibrary: 'Фото-кітапхана',
      photoLibraryOpen: 'Камераны/фото-кітапхананы ашу',
      photography: 'Фотография',
      placeholderFrom: `{{from}}-дан`,
      placeholderTo: `{{to}}-ға`,
      price: 'Бағасы',
      privacyPolicy: 'Құпиялылық шарт',
      product: 'Тауар',
      productCategory: 'Тауар санаты',
      product_other: 'Тауарлар',
      profilePersonal: 'Жеке кабинет',
      publicOffer: 'Қоғамдық ұсыныс',
      refresh: 'Жаңарту',
      refreshPage: 'Бетті жаңарту',
      rejected: 'Қабылданбады',
      request: 'Сұрау',
      request_other: 'Сұраулар',
      requests: 'Сұраулар',
      requestsActive: 'Белсенді сұраулар',
      requestsHistory: 'Сұрау тарихы',
      reset: 'Қалпына келтіру',
      retail: 'Бөлшек',
      rightsReserved: 'Все права защищены',
      save: 'Сақтау',
      sellerPremium: 'Премиум сатушы',
      sent: 'Жіберілді',
      settings: 'Параметрлер',
      shop: 'Дүкен',
      showMore: 'Әлі де көрсету',
      termsOfService: 'Қызмет көрсету шарттары',
      title: 'Атауы',
      verifiedSeller: 'Тексерілген сатушы',
      wholesale: 'Көтерме',
      yes: 'Иә',
    },
    forms: {
      linkMustBeHttps: 'Сілтеме https:// арқылы басталуы керек',
      maxLength: 'Енгізілетін өрістің ең ұзын ұзындығы {{length}} таңба болуы керек',
      minLength: 'Енгізілетін өрістің ең қысқа ұзындығы {{length}} таңба болуы керек',
      phoneError: "Телефон нөмірі келесі форматта енгізілуі керек: '+7(999)-999-99-99'",
      required: 'Міндетті өріс',
      requiredCategories: 'Өтінеміз, санатты таңдаңыз',
      requiredMarket: 'Өтінеміз, базарды таңдаңыз',
    },
    home: {
      becomeBuyer: 'Сатып алушы болу',
      becomeSeller: 'Сатушы болу',
      beneficialForEveryone: 'Выгодно для всех',
      economyTime: 'Уақыт пен ақшаны үнемдеңіз',
      findProfitableOffers: 'Барлық базарды араламай-ақ тиімді ұсыныстар табыңыз',
      forBuyers: 'Сатып алушыларға',
      forBuyersDescription: 'Тауарларды ең жақсы бағамен табуға уақытты үнемдеңіз.',
      forSellers: 'Сатушыларға',
      forSellersDescription: 'Сату нүктесіне келушілер ағынын арттыру',
      helpUsers:
        'Базарға келушілерге сіздің нүктеңізді табуға және айналымдарыңызды арттыруға көмектесеміз',
      howItWorks: 'Бұл қалай жұмыс істейді',
      howItWorks1: 'Базардан іздеген тауарыңызды іздеу жолағына енгізіңіз',
      howItWorks2: 'Базардағы сатушылардан ұсыныстар алыңыз',
      howItWorks3: 'Ең тиімді ұсыныстарды таңдаңыз',
      howItWorks4: 'Геолокацияның көмегімен сатушыны тез табыңыз',
      inputProductName: 'Тауар атауын енгізіңіз',
      sellMore: 'GoBazar арқылы көбірек сатыңыз',
      weHelpSellersAndBuyers: 'Біз сатушылар мен базарға келушілерге бір-бірін табуға көмектесеміз',
    },
    messages: {
      offersFound: 'Сіздің сұрауыңыз бойынша ұсыныстар табылды:',
      offersNoResponded: 'Әзірге сіз жауап берген сұраулар жоқ',
      offersNotFound: 'Ұсыныстар табылмады',
      offersWaitingSellers: 'Сатушылардан ұсыныстар күтілуде',
      pageNotFound: 'Бет табылмады',
      productNotFound: 'Өнім табылмады',
      requestNotFound: 'Сұрау табылмады',
      requestOrOfferNotFound: 'Талап немесе ұсыныс табылмады',
      requestsNoCustomers: 'Әзірге сатып алушылардан сұраулар жоқ',
      requestsNotCreated: 'Сіз әлі сұраулар жасаған жоқсыз',
      shopNoCategories: 'Сізде санаттар көрсетілмеген!',
      shopNoCategoriesAdd: 'Бұған тауарларды сатуға профиліңізге санаттарды қосу керек',
      shopNoCategoriesContactAdministrator:
        'Сатып алушылардан сұрауларды алу үшін администратормен хабарласыңыз.',
      shopNotFound: 'Дүкен табылмады',
      shopsNotFound: 'Дүкендер табылмады',
    },
    moderation: {
      accountUnderReview: 'Есептік жазбаңыз модерацияда.',
      accountUnderReviewDescription:
        'Бұл 24 сағатқа дейін созылуы мүмкін, бірақ тез көрсету әрқашан 10 минут ішінде болады. Бетті кейінірек жаңартыңыз',
      pageTitle: 'Есептік жазбаны модерациялау',
      pleaseWait: 'Есептік жазба деректерін тексергенше күтіңіз.',
    },
    offers: {
      addPhoto: 'Тауардың фотосуреттерін қосыңыз',
      canNotAddManyPhotos: 'Ұсынысқа 3-тен көп сурет қоса алмайсыз',
      chooseOfferStatus: 'Ұсыныс мәртебесін таңдаңыз',
      fastAnswer: 'Жылдам жауап',
      inputPrice: 'Бағаны көрсетіңіз',
      makeOffer: 'Ұсыныс жасау',
      offerDate: 'Ұсыныс күні мен уақыты:',
      offerDateFrom: 'Сұрау күні, бастап',
      offerDateTo: 'Сұрау күні, дейін',
      offerDeclined: 'Сұрау қабылданбады',
      offerSentToCustomer: 'Ұсыныс сатып алушыға жіберілді',
      offersBanner:
        'Өтініштер <span class="font-semibold">15 минут белсенді.</span> Алғашқы 2 минутта жауап беру сату мүмкіндігін 80%-ке арттырады',
      offersFromSellers: 'Сатушылардан ұсыныстар',
      productCategories: 'Тауар санаты:',
      productIsAvailable: 'Тауар бар',
      productPhotos: 'Тауардың фотосуреттері',
      recommendedSellers: 'Сіздің сұранысыңыз бойынша ұсынылған сатушылар',
      similar: 'Ұқсас ұсыныстар:',
      trySeeSimilar: 'Ұқсас ұсыныстарды көре аласыз',
    },
    premium: {
      contactAdministrator: ' Администратормен хабарласыңыз',
      contactAdministratorBy: ' Администратормен хабарласыңыз',
      contactUs: 'Бізбен байланысыңыз',
      dailyLimit: 'Күнделікті шектеу',
      dailyLimitEnded: 'Күнделікті шектеуді аяқтадыңыз.',
      limitUsedUp: 'Күнделікті шектеу аяқтадылды.',
      noLimitProductCreate: 'Ұсыныстар жасауға шектеу жоқ',
      premiumBefits: 'Premium артықшылықтары:',
      priorityShowcase: 'Сатып алушылар арасында дүкенді басымдықпен көрсету',
      tariffPeriod: 'Тариф мерзімі',
      tariffPeriodEnded: 'Тариф мерзімі аяқталды',
      tariffPeriodEnding: 'Тариф мерзіміңіздің аяқталуы жақындап келеді',
      tariffYourPeriodEnded: 'Тариф мерзіміңіз аяқталды',
      topUpAccountBalance: 'Есепшотыңызды толтырыңыз немесе администратормен хабарласыңыз',
      trialPeriod: 'сынақ мерзімі',
      trialPeriodSuccess: 'Сынақ тарифі сәтті қосылды',
      tryFree: 'Тегін сынап көру',
    },
    product: {
      addProduct: 'Тауарды қосу',
      createProduct: 'Тауарды жасау',
      editProduct: 'Тауарды өзгерту',
      newProduct: 'Жаңа тауар',
      priceFrom: 'Бағасы, бастап',
      priceFromTo: `{{from}}-дан {{to}}-ға дейін`,
      priceTo: 'Бағасы, дейін',
      productDescription: 'Тауардың сипаттамасы',
      updateProduct: 'Тауарды жаңарту',
    },
    requests: {
      addPhoto: 'Фотосуреттер қосу',
      createNewRequest: 'Жаңа сұраныс жасау',
      customerInfoBanner: 'Әдетте сатушылар ұсыныстарын 3-5 минутта дайындайды.',
      findProduct: 'Өнімді табу',
      inputRequest: 'Сұрау енгізіңіз',
      priceNotEmpty: '"Бағасы" өрісі бос болмауы керек және тек сандарды қамтуы керек',
      rejectRequest: 'Сұрауды қабылдамау',
      requestSent: 'Өтініш жіберілді.',
    },
    screens: {
      main: 'Басты бет',
      requestDetails: 'Сұрау туралы мәлімет',
    },
    seller: {
      about: 'Сатушы туралы',
    },
    shop: {
      about: 'Дүкен туралы',
      accountNumberCopied: 'Тұлғалық шот нөмірі алмасу буферіне көшірілді',
      addProfilePhoto: 'Дүкен профилінің суретін қосыңыз',
      appDoesNotHaveAccessTo: 'Қосымшаға рұқсат жоқ',
      canNotAddManyPhotos: '3-тен көп сурет қоса алмайсыз',
      card: 'Дүкен картасы',
      contactGoBazarText:
        'Сәлеметсіз бе!\nGoBazar сатушы қосымшасынан жазып отырмын.\nМынаған қатысты хабарласқым келеді...',
      createCard: 'Дүкен парағын жасау',
      deleteAccount: 'Есептік жазбаны жою',
      deleteConfirmation: 'Есептік жазбаны жоюға сенімдісіз бе?',
      description: 'Дүкеннің сипаттамасы',
      inputAddress: 'Мекенжайды енгізіңіз',
      inputFullNameShort: 'Т.А.Ә. енгізіңіз',
      logoutConfirmation: 'Есептік жазбадан шығуға сенімдісіз бе?',
      marketsLoading: 'Базарларды жүктеу...',
      rowPlace: 'қатар, орын',
      selectMarket: 'Базарды таңдау',
      selectPhoto: 'Суретті таңдау',
      sellerFullNameShort: 'Сатушының Т.А.Ә.',
      shopAddress: 'Дүкеннің мекен-жайы',
      shopName: 'Дүкен атауы',
      shopPhotos: 'Дүкеннің фотосуреттері',
      shopSaved: 'Дүкен сақталды',
      toAddPhotosAllowSettings:
        'Суреттерді қосу үшін, телефонның баптауларында қосымшаға рұқсат беріңіз.',
      wantToEditCategory:
        'Егер сізге тауар санаттарын өңдеу қажет болса, қызмет администратормен хабарласыңыз',
    },
    tradeTypes: {
      RETAIL: 'Бөлшек',
      RETAILCommerce: 'Бөлшек сауда',
      RETAILRequest: 'Бөлшек сұраныс',
      WHOLESALE: 'Көтерме',
      WHOLESALECommerce: 'Көтерме сауда',
      WHOLESALERequest: 'Көтерме сұраныс',
      choose: 'Сауда түрін таңдаңыз',
      tradeType: 'Сауда түрі',
    },
    update: {
      appUpdating: 'Қосымша жаңартылуда',
      downloadedRestarting: 'Жаңарту жүктелді, қосымша қайта жүктелуде',
      downloading: 'Жаңарту жүктелуде',
      update: 'Жаңарту',
    },
  },
};
